import { useSelector } from 'react-redux';
import { getActiveOrganizationId } from '@artemis/store/organization/selectors';
import { getMerchantSupportsGroupOrdering } from '@artemis/store/merchant/selectors';
import { IN_STORE_PARAM } from '@artemis/utils/query/constants';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';
import { getOrderSource } from '@artemis/store/order/selectors';
import { ORDER_SOURCE } from '@artemis/utils/constants';

/**
 * Users can create group orders when:
 * - the merchant has group ordering enabled in partner portal
 * - the order source is not GOOGLE_FOOD
 * - one of two conditions are met:
 *   - the current organization is whitelisted in RT_CREATE_GROUP_ORDER_ORGID_ALLOWLIST
 *   - the user is NOT on a subdomain
 *
 * @returns {bool} true if user can create a group order
 */
const useCanCreateGroupOrder = () => {
  const isInStore = useQueryCheck(IN_STORE_PARAM);
  const orderSource = useSelector(getOrderSource);
  const organizationId = useSelector(getActiveOrganizationId);
  const merchantSupportsGroupOrdering = useSelector(
    getMerchantSupportsGroupOrdering,
  );

  if (isInStore || orderSource === ORDER_SOURCE.GOOGLE_FOOD) {
    return false;
  }

  const orgIdAllowList =
    process.env.RT_CREATE_GROUP_ORDER_ORGID_ALLOWLIST || [];

  const orgAllowed =
    !!organizationId && orgIdAllowList.includes(organizationId);

  return merchantSupportsGroupOrdering && (orgAllowed || !organizationId);
};

export default useCanCreateGroupOrder;
