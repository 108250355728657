import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMerchantId,
  getMerchantSupportsDelivery,
} from '@artemis/store/merchant/selectors';
import { merchantDeliveryInfoLoading } from '@artemis/store/merchant/slice';
import useIsSAPWorkspace from '@artemis/utils/query/useIsSAPWorkspace';

export const useLoadMerchantDeliveryInfo = () => {
  const dispatch = useDispatch();

  const isFromSapWorkspaceList = useIsSAPWorkspace();
  const supportsDelivery =
    useSelector(getMerchantSupportsDelivery) && !isFromSapWorkspaceList;
  const merchantId = useSelector(getMerchantId);

  useEffect(() => {
    if (merchantId && supportsDelivery) {
      dispatch(merchantDeliveryInfoLoading({ merchantId }));
    }
  }, [merchantId, supportsDelivery]);
};
