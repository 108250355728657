import React, { useEffect, useState } from 'react';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { RtDropdown, rtColors } from '@ritualco/ritual-frontend-components';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  getDefaultLocale,
  getSupportedLocales,
} from '@artemis/store/menu/selectors';
import {
  FormattedMessage,
  useFormatMessage,
} from '@artemis/integrations/contentful/utils';
import { useRemoteConfig } from '@artemis/integrations/remoteConfig';
import { loadCart } from '@artemis/store/cart/slice';
import { getMerchantId } from '@artemis/store/merchant/selectors';
import { loadMenu } from '@artemis/store/menu/slice';
import { track } from '@artemis/utils/analytics';
import Cookies from '@artemis/utils/cookies';
import { MENU_DEBUG_PARAM } from '@artemis/utils/query/constants';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';
import {
  EVENT_ACTION_LANGUAGE_SELECT,
  EVENT_ACTION_LANGUAGE_SELECTOR_IMPRESSION,
} from './analytics';

const Container = styled.div`
  padding: 0 16px 16px;
  grid-area: language-selector;
  ${({ theme }) => theme.isTablet`
    display: flex;
    justify-content: space-between;
    align-items:center;
  `}
`;

const StyledDropdown = styled(RtDropdown)`
  select {
    color: ${({ theme }) => theme.rtColors.black900};
  }
`;

const LanguageSelector = () => {
  const supportedLocales = useSelector(getSupportedLocales);
  const merchantDefaultLocale = useSelector(getDefaultLocale);
  const merchantId = useSelector(getMerchantId);
  const [locale, setLocale] = useState('');
  const isDebugMode = useQueryCheck(MENU_DEBUG_PARAM);
  const { getBoolean, configLoaded, getJSON } = useRemoteConfig();
  const languageSelectorEnabled = getBoolean('roo_language_selector_enabled');
  const merchantsWithSelector = getJSON(
    'roo_language_selector_merchant_enabled',
  );
  const isCurrentMerchantSelectorForceEnabled =
    merchantsWithSelector?.includes(merchantId);

  const dispatch = useDispatch();

  const selectorLabel = useFormatMessage({ entry: 'menu.languageSelector' });
  const languageNameEntry = {
    en_CA: useFormatMessage({ entry: 'menu.language.en_CA' }),
    en_US: useFormatMessage({ entry: 'menu.language.en_US' }),
    en_GB: useFormatMessage({ entry: 'menu.language.en_GB' }),
    en_AU: useFormatMessage({ entry: 'menu.language.en_AU' }),
    fr_CA: useFormatMessage({ entry: 'menu.language.fr_CA' }),
    zh_HK: useFormatMessage({ entry: 'menu.language.zh_HK' }),
  };

  const getDefaultLanguage = lan => {
    if (!lan || !supportedLocales) return merchantDefaultLocale;
    if (supportedLocales.includes(lan)) {
      return lan;
    }
    return (
      supportedLocales.find(l => l.startsWith(lan.split('-')[0])) ||
      merchantDefaultLocale
    );
  };

  useEffect(() => {
    const userLang = Cookies.get('rt-lang-menu');
    const rtLang = Cookies.get('rt-lang');
    const defaultLanguage = getDefaultLanguage(userLang || rtLang);
    setLocale(defaultLanguage);
  }, []);

  const selectorSupported =
    (languageSelectorEnabled || isCurrentMerchantSelectorForceEnabled) &&
    supportedLocales?.length >= 2;

  useEffect(() => {
    if (selectorSupported) {
      track(EVENT_ACTION_LANGUAGE_SELECTOR_IMPRESSION);
    }
  }, [selectorSupported]);

  useEffect(() => {
    if (configLoaded && !languageSelectorEnabled) {
      Cookies.remove('rt-lang-menu');
    }
  }, [configLoaded, languageSelectorEnabled]);

  if (!selectorSupported) return null;

  const languageOptions = supportedLocales?.map(localeOption => ({
    name: localeOption,
    label: languageNameEntry[localeOption],
    value: localeOption,
  }));

  const onLocaleChange = e => {
    const language = e.target.value;
    const languageAbbr = language?.split('_')[0];
    setLocale(language);
    Cookies.set('rt-lang-menu', languageAbbr, {
      domain: process.env.RT_SHARED_COOKIE_DOMAIN,
    });
    dispatch(loadMenu({ debug: isDebugMode }));
    dispatch(loadCart({ merchantId }));
    track(EVENT_ACTION_LANGUAGE_SELECT, {
      SELECTED_LANGUAGE: languageAbbr,
    });
  };

  return (
    <Container>
      <p>
        <FormattedMessage
          entry="menu.language.instruction"
          values={{ value: supportedLocales?.length }}
        />
      </p>
      <StyledDropdown
        aria-label={selectorLabel}
        name="language-selector"
        variant="outlined"
        placeholder="Select a Language"
        label={null}
        value={locale}
        onChange={onLocaleChange}
        options={languageOptions}
        leftComponent={
          <LanguageOutlinedIcon
            sx={{
              color: rtColors.black500,
              fontSize: 17,
              verticalAlign: 'middle',
            }}
          />
        }
      />
    </Container>
  );
};

export default LanguageSelector;
