import React from 'react';
import PropTypes from 'prop-types';
import { Caption, Label } from './components';

const FulfillmentTypeLabel = ({ label, caption }) => (
  <div>
    <Label>{label}</Label>
    {caption && <Caption aria-hidden="true">{caption}</Caption>}
  </div>
);

FulfillmentTypeLabel.propTypes = {
  label: PropTypes.node,
  caption: PropTypes.node,
};

export default FulfillmentTypeLabel;
