import React, { useContext, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import Button from '@artemis/components/Button';
import CustomModal from '@artemis/components/Modal';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import CancelIcon from '@mui/icons-material/Cancel';
import { getMerchantName } from '@artemis/store/merchant/selectors';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';
import {
  HIDE_EXPRESS_SERVICE_PARAM,
  IN_STORE_PARAM,
} from '@artemis/utils/query/constants';
import { getIsExpressServiceModalOpen } from '@artemis/store/menu/selectors';
import { setExpressServiceModalOpen } from '@artemis/store/menu/slice';
import { useAddQueryParams } from '@artemis/utils/query';

const CloseButton = styled.button`
  z-index: 1;
  background: ${props => props.theme.palette.common.white};
  opacity: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 14px;
  right: 14px;
  cursor: pointer;
  border: none;
  position: absolute;
`;

const BrandIcon = styled.img`
  margin: 0 auto;
  display: block;
  width: 125px;
  height: 125px;
`;

const ModalWrapper = styled(CustomModal)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Modal = styled.div`
  position: relative;
  background-color: ${props => props.theme.palette.background.card};
  border-radius: 20px 20px 0 0;
  max-width: 450px;
  width: 100%;
  align-self: center;
`;

const Content = styled.div`
  padding: 20px;
  padding-top: 56px;
`;

const Title = styled.h1`
  text-align: center;
  ${props => props.theme.typography.h4};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  margin-top: 22px;
`;

const Description = styled.p`
  text-align: center;
  margin: auto;
  margin-top: 10px;
  max-width: 315px;
  ${props => props.theme.typography.body};
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const Highlight = styled.span`
  color: ${props => props.theme.palette.primary};
  font-size: inherit;
  font-weight: inherit;
`;

const FallbackIconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const FallbackIcon = styled(ResponsiveImage)`
  height: 125px;
  > img {
    object-fit: contain;
  }
`;

/**
 * A modal that explains in store ordering
 */
const ExpressServiceModal = () => {
  const dispatch = useDispatch();
  const addQueryParams = useAddQueryParams();
  const theme = useContext(ThemeContext);
  const merchantName = useSelector(getMerchantName);
  const isInStore = useQueryCheck(IN_STORE_PARAM);
  const isModalOpen = useSelector(getIsExpressServiceModalOpen);
  const setIsOpen = isOpen => dispatch(setExpressServiceModalOpen({ isOpen }));
  const isExpressServiceModalHidden = useQueryCheck(HIDE_EXPRESS_SERVICE_PARAM);

  // Open the modal automatically, if `inStore=true` query param is present, and `hideExpressServiceModal=true` is not present
  useEffect(() => {
    if (isInStore && !isExpressServiceModalHidden) {
      setIsOpen(true);
    }
  }, [isInStore, isExpressServiceModalHidden]);

  const onClose = () => {
    addQueryParams({ [HIDE_EXPRESS_SERVICE_PARAM]: 'true' });
    setIsOpen(false);
  };

  return (
    <ModalWrapper isOpen={isModalOpen}>
      <Modal>
        <Content>
          <CloseButton onClick={onClose} data-testid="modal-close">
            <CancelIcon sx={{ color: 'black', fontSize: 42 }} />
          </CloseButton>

          {theme.iconUrl ? (
            <BrandIcon src={theme.iconUrl} width="80" height="80" />
          ) : (
            <FallbackIconWrapper>
              <FallbackIcon id="menu.expressServiceModal.fallbackLogo.img" />
            </FallbackIconWrapper>
          )}
          <Title>
            <FormattedMessage
              entry="menu.expressServiceModal.title"
              values={{ highlight: text => <Highlight>{text}</Highlight> }}
            />
          </Title>
          <Description>
            <FormattedMessage
              entry="menu.expressServiceModal.description"
              values={{ merchantName }}
            />
          </Description>
          <ButtonContainer>
            <StyledButton buttonType="solid" onClick={onClose}>
              <FormattedMessage entry="menu.expressServiceModal.orderButton" />
            </StyledButton>
          </ButtonContainer>
        </Content>
      </Modal>
    </ModalWrapper>
  );
};

export default ExpressServiceModal;
