import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { setHostInfoModal } from '@artemis/store/groupOrder/slice';
import { useRemoveQueryParameter } from '@artemis/utils/query';
import {
  FEATURE_INFO_PARAM,
  FEATURE_INFO_VALUES,
} from '@artemis/utils/query/constants';
import useCanCreateGroupOrder from './useCanCreateGroupOrder';

/**
 * Opens modal if query param 'learn=groupOrdering' is present
 */
const useGroupOrderHostInfoModal = () => {
  const dispatch = useDispatch();
  const { query } = useRouter();
  const { [FEATURE_INFO_PARAM]: learn } = query;
  const removeQueryParameter = useRemoveQueryParameter();
  const canCreateGroupOrder = useCanCreateGroupOrder();

  useEffect(() => {
    if (learn === FEATURE_INFO_VALUES.GROUP_ORDERING) {
      if (canCreateGroupOrder) {
        dispatch(setHostInfoModal({ isOpen: true }));
      }
      removeQueryParameter(FEATURE_INFO_PARAM);
    }
  }, [learn, canCreateGroupOrder]);
};

export default useGroupOrderHostInfoModal;
