const SEPARATOR = ' \u2022 ';

function SeparatorList({ children }) {
  const filtered = children.filter(Boolean);
  const seperated = filtered.flatMap((child, index) =>
    index + 1 >= filtered.length ? [child] : [child, SEPARATOR],
  );

  return seperated;
}

export default SeparatorList;
