import React from 'react';
import { useSelector } from 'react-redux';
import { GroupOrderStatus } from '@artemis/components/GroupOrder/GroupOrderStatus';
import {
  getFulfillmentScheduledTime,
  getGroupDisplay,
  getIsConsumerFlow,
  getIsCurrentUserHost,
  getOrderByTime,
  isDetailsLoading,
} from '@artemis/store/groupOrder/selectors';
import { getMerchantTimeZone } from '@artemis/store/merchant/selectors';
import ViewGroupOrder from '@artemis/containers/GroupOrder/ViewGroupOrder';
import { FROM_VALUES } from '@artemis/utils/query/constants';

const GroupOrderContainer = () => {
  const isLoading = useSelector(isDetailsLoading);
  const groupDisplay = useSelector(getGroupDisplay);
  const isConsumerFlow = useSelector(getIsConsumerFlow);
  const isCurrentUserHost = useSelector(getIsCurrentUserHost);
  const orderByTime = useSelector(getOrderByTime);
  const timeZone = useSelector(getMerchantTimeZone);
  const scheduledTime = useSelector(getFulfillmentScheduledTime);

  if (isLoading) return null;

  return (
    <GroupOrderStatus
      groupDisplay={groupDisplay}
      isConsumerFlow={isConsumerFlow}
      isCurrentUserHost={isCurrentUserHost}
      scheduled={!!scheduledTime}
      orderByTime={orderByTime}
      timeZone={timeZone}
      viewButton={<ViewGroupOrder from={FROM_VALUES.MENU} />}
    />
  );
};

export default GroupOrderContainer;
