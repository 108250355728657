import React, { useContext, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '@artemis/store/fulfillment/slice';
import { openModal as openSchedulingModal } from '@artemis/store/scheduling/slice';
import {
  getMerchantId,
  getMerchantName,
  getIsMerchantAuthModeCheckoutOTP,
  getMerchantAuthMode,
} from '@artemis/store/merchant/selectors';
import {
  getCart,
  getDeliveryAddressDetail,
  getCanOrderNow,
  getRequiresScheduling,
} from '@artemis/store/cart/selectors';
import { getSupportedLocales } from '@artemis/store/menu/selectors';
import useIsHqOLinkout from '@artemis/integrations/embed/hqo/useIsHqOLinkout';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import RemoteConfigContext from '@artemis/integrations/remoteConfig/context';
import AuthenticationContext from '@artemis/integrations/auth/AuthenticationContext';
import {
  logEvent,
  track,
  EVENT_PARAM_TYPE_INTEGER,
  EVENT_PARAM_TYPE_STRING,
  EVENT_PARAM_TYPE_BOOL,
} from '@artemis/utils/analytics';
import { CHECKOUT_LOGIN_PARAM } from '@artemis/utils/query/constants';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';
import { isInIframe } from '@artemis/utils/iframe';
import getMenuLanguage from '@artemis/utils/getMenuLanguage';
import { getIsEmbeddedHqO } from '@artemis/store/embed/selectors';
import useCheckoutUrl from '@artemis/containers/CheckoutPage/useCheckoutUrl';
import {
  EVENT_ACTION_CHECKOUT_CLICK,
  EVENT_ACTION_CHECKOUT_LOGIN_POPUP,
} from './analytics';
import useIsOrderLaterOnly from './useIsOrderLaterOnly';
import useIsDeliveryOnly from './useIsDeliveryOnly';

const getShouldRedirectToLogin = ({
  isAuthenticated,
  isEmbeddedHqO,
  isHqOLinkout,
  isMerchantAuthModeCheckoutOTP,
  isCheckoutLoginDisabledByQueryParam,
}) => {
  if (!isAuthenticated) {
    if (isEmbeddedHqO) return true; // Users must be logged in for embedded HqO checkout
    if (isHqOLinkout) return false; // Users should *never* be prompted to login within the HqO linkout
    if (isCheckoutLoginDisabledByQueryParam) return false; // Users should not be prompted to login if disabled by query param
    if (isMerchantAuthModeCheckoutOTP) return true;
  }
  return false;
};

const useHandleCheckoutClick = () => {
  const dispatch = useDispatch();
  const { authenticated, setAuthModal } = useContext(AuthenticationContext);
  const { getBoolean } = useContext(RemoteConfigContext);
  const enableCheckoutLogin = getBoolean('r1_enable_checkout_login');
  const checkoutUri = useCheckoutUrl();
  const orderLaterOnlyEnabled = useIsOrderLaterOnly();
  const hidePickup = useIsDeliveryOnly();
  const isHqOLinkout = useIsHqOLinkout();
  const r1AuthEnabled = !isInIframe();
  const merchantId = useSelector(getMerchantId);
  const merchantName = useSelector(getMerchantName);
  const cart = useSelector(getCart);
  const merchantAuthMode = useSelector(getMerchantAuthMode);
  const supportedLocales = useSelector(getSupportedLocales);
  const requiresScheduling = useSelector(state =>
    getRequiresScheduling(state, orderLaterOnlyEnabled),
  );
  const addressDetail = useSelector(getDeliveryAddressDetail);
  const isEmbeddedHqO = useSelector(getIsEmbeddedHqO);
  const isMerchantAuthModeCheckoutOTP = useSelector(
    getIsMerchantAuthModeCheckoutOTP,
  );
  const canOrderNow = useSelector(getCanOrderNow);
  const isCheckoutLoginDisabledByQueryParam =
    useQueryCheck(CHECKOUT_LOGIN_PARAM);

  const shouldRedirectToLogin = getShouldRedirectToLogin({
    isAuthenticated: authenticated,
    isEmbeddedHqO,
    isHqOLinkout,
    isMerchantAuthModeCheckoutOTP,
    isCheckoutLoginDisabledByQueryParam,
  });

  // Logs event when user clicks checkout button
  const logCheckoutClick = useCallback(() => {
    const params = [
      {
        key: 'MERCHANT_ID',
        value: merchantId,
        type: EVENT_PARAM_TYPE_STRING,
      },
      {
        key: 'ITEM_IDS',
        value: cart?.cartItems?.map(item => item.cartItemId).join('|') || '',
        type: EVENT_PARAM_TYPE_STRING,
      },
      {
        key: 'SUBTOTAL_MICRO',
        value: cart?.financialInfo?.subtotalMicro || 0,
        type: EVENT_PARAM_TYPE_INTEGER,
      },
      {
        key: 'CURRENCY_CODE',
        value: cart?.currencyCode || 'NULL',
        type: EVENT_PARAM_TYPE_STRING,
      },
      {
        key: 'MERCHANT_AUTH_MODE',
        value: merchantAuthMode,
        type: EVENT_PARAM_TYPE_STRING,
      },
      {
        key: 'INCENTIVE_IDS',
        value: cart?.incentives
          ?.map(incentive => incentive.incentiveId)
          .join('|'),
        type: EVENT_PARAM_TYPE_STRING,
      },
      {
        key: 'INCENTIVE_INVALID',
        value: cart?.incentives?.some(incentive => !incentive.valid)
          ? 'TRUE'
          : 'FALSE',
        type: EVENT_PARAM_TYPE_BOOL,
      },
    ];

    logEvent(EVENT_ACTION_CHECKOUT_CLICK, params);

    track(EVENT_ACTION_CHECKOUT_CLICK, {
      SELECTED_LANGUAGE: getMenuLanguage(supportedLocales),
    });
  }, [cart, merchantAuthMode, merchantId, supportedLocales]);

  const handleCheckout = useCallback(
    event => {
      if (hidePickup && !addressDetail) {
        dispatch(openModal());
      } else if (shouldRedirectToLogin) {
        event.preventDefault();
        setAuthModal({
          showLoginModal: true,
          title: <FormattedMessage entry="login.title.checkout" />,
          newReturnUri: checkoutUri,
          checkoutOTP: true,
        });
      } else if (
        !authenticated &&
        enableCheckoutLogin &&
        r1AuthEnabled &&
        canOrderNow
      ) {
        event.preventDefault();
        track(EVENT_ACTION_CHECKOUT_LOGIN_POPUP, {
          MERCHANT_ID: merchantId,
          MERCHANT_NAME: merchantName,
        });
        setAuthModal({
          showLoginModal: true,
          title: <FormattedMessage entry="login.title.checkout" />,
          showGuestCheckout: true,
          newReturnUri: checkoutUri,
        });
      } else if (canOrderNow) {
        // handles redirecting when only scheduling modal is triggered
        window.location.assign(checkoutUri);
      }
    },
    [
      hidePickup,
      addressDetail,
      shouldRedirectToLogin,
      authenticated,
      checkoutUri,
      canOrderNow,
      merchantId,
      merchantName,
      enableCheckoutLogin,
      r1AuthEnabled,
      setAuthModal,
    ],
  );

  const handleCheckoutClick = useCallback(
    event => {
      logCheckoutClick();

      if (requiresScheduling) {
        event.preventDefault();
        dispatch(openSchedulingModal({ isCheckout: true }));
        return;
      }

      handleCheckout(event);
    },
    [logCheckoutClick, requiresScheduling, handleCheckout],
  );

  return { handleCheckoutClick, handleCheckout };
};

export default useHandleCheckoutClick;
