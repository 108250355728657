import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Image from 'next/image';
import {
  FormattedMessage,
  useFormatMessage,
} from '@artemis/integrations/contentful/utils';
import { useRemoteConfig } from '@artemis/integrations/remoteConfig';
import ItemPrice from './ItemPrice';
import { MenuItemShape } from './propTypes';
import CalorieRange from './CalorieRange';

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  grid-area: text;

  > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Container = styled.div.attrs({
  tabIndex: 0,
  role: 'button',
})`
  padding: 16px;
  border-radius: 4pt;
  background-color: ${props => props.theme.palette.common.white};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border: none;
  text-align: left;
  transition: 0.25s box-shadow;

  * {
    user-select: none;
  }

  &:hover,
  &:focus {
    box-shadow: ${props => props.theme.shadows.shadow1};
  }

  ${props => props.theme.isTablet`
    display: grid;
    grid-template-rows: auto;
    grid-template-areas: 'text img';
    grid-column-gap: 16px;
    grid-column-gap: 26px;
  `}

  ${({ outOfStock }) => outOfStock && 'opacity: 0.5;'}
`;

const Title = styled.p``;

const PriceRow = styled.div``;

const Description = styled.span`
  word-break: break-word;
  ${props => props.theme.typography.bodySmall};
  color: ${props => props.theme.palette.grey[600]};
`;

const ITEM_IMAGE_SIZE = 90;
const ItemImageContainer = styled.div`
  overflow: hidden;
  align-self: center;
  object-fit: cover;
  object-position: center;
  height: ${ITEM_IMAGE_SIZE}px;
  width: ${ITEM_IMAGE_SIZE}px;
  min-width: ${ITEM_IMAGE_SIZE}px;
  border-radius: 15px;
`;

// List Space and Enter keycodes as keydown triggers to simulate a click
const itemTriggerKeys = [13, 32];

const MenuItem = ({
  item: {
    title,
    description,
    price,
    imageUrl,
    availability,
    perk,
    calorieRange,
  },
  onClick,
}) => {
  const { getBoolean } = useRemoteConfig();
  const isCaloriesEnabled = getBoolean('rw_menu_calories_enabled');
  const menuItemLabel = useFormatMessage({ entry: 'global.menuItem' });
  return (
    <Container
      aria-label={`${menuItemLabel}: ${title}`}
      onClick={onClick}
      onKeyDown={e => {
        if (e.keyCode && itemTriggerKeys.indexOf(e.keyCode) > -1) {
          onClick(e);
        }
      }}
      img={!!imageUrl}
      outOfStock={!availability.isAvailable}
    >
      <FlexColumn>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <PriceRow>
          <ItemPrice price={price} perk={perk || null} />
          {!availability.isAvailable && (
            <i>
              <FormattedMessage entry="menu.separator" />
              <FormattedMessage entry="menu.out_of_stock" />
            </i>
          )}
          {isCaloriesEnabled && calorieRange && (
            <>
              <FormattedMessage entry="menu.separator" />
              <CalorieRange range={calorieRange} />
            </>
          )}
        </PriceRow>
      </FlexColumn>
      {imageUrl && (
        <ItemImageContainer>
          <Image
            src={imageUrl}
            unoptimized={!imageUrl.includes('googleusercontent.com')}
            loader={({ src, width }) => {
              /* Only googleusercontent supports resizing with suffixes.
              See https://developers.google.com/people/image-sizing */
              if (!width || !src.includes('googleusercontent.com')) {
                return `${src}`;
              }
              return `${src}${width ? `=c-w${width}` : ''}`;
            }}
            objectFit="cover"
            width={`${ITEM_IMAGE_SIZE}`}
            height={`${ITEM_IMAGE_SIZE}`}
            alt={title}
            role="presentation"
          />
        </ItemImageContainer>
      )}
    </Container>
  );
};

MenuItem.propTypes = {
  item: MenuItemShape,
  onClick: PropTypes.func,
};

export default MenuItem;
