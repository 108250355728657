import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AvailabilityHours from '@artemis/components/AvailabilityHours';
import MenuItem from './MenuItem';

const Container = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey['200']};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;
  padding-bottom: 32px;
  width: 100%;
  top: -100px;
`;

const StyledH2 = styled.h2`
  ${props => props.theme.typography.h6};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  padding: 16px 16px;
`;

const ItemGrid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > :not(:last-child) {
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.isDesktop`
    > :not(:last-child) {
      margin-bottom: 0;
    }
    grid-gap: 16px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
  `}
`;

const MenuGroup = ({
  id,
  title,
  items,
  onItemSelect,
  availabilityHours,
  isDebugMode = false,
}) => (
  <Container>
    <StyledH2 id={id} bold>
      {title}
    </StyledH2>
    {isDebugMode && <AvailabilityHours availabilityHours={availabilityHours} />}
    <ItemGrid>
      {items.map(item => (
        <MenuItem
          key={item.title}
          data-testid={item.title}
          onClick={() => onItemSelect(item.id, title)}
          item={item}
        />
      ))}
    </ItemGrid>
  </Container>
);

MenuGroup.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array,
  onItemSelect: PropTypes.func,
  availabilityHours: PropTypes.array,
  isDebugMode: PropTypes.bool,
};

MenuGroup.defaultProps = {
  items: [],
  onItemSelect: () => {},
};

export default MenuGroup;
