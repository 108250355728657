import React, { useContext } from 'react';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';

const TextDisclosure = styled.div`
  display: inline-flex;
  align-items: center;
`;

const ButtonDisclosure = styled.button`
  all: unset;
  display: inline-flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
`;

export const ServiceFeeDisclosure = ({ isClickable, onClick }) => {
  const theme = useContext(ThemeContext);
  const infoIconStyle = {
    fontSize: '18px',
    color: theme.rtColors.black900,
    ml: 0.5,
  };

  if (isClickable) {
    return (
      <ButtonDisclosure onClick={onClick}>
        <FormattedMessage entry="menu.serviceFeesApply" />
        <InfoIcon sx={infoIconStyle} />
      </ButtonDisclosure>
    );
  }

  return (
    <TextDisclosure>
      <FormattedMessage entry="menu.serviceFeesApply" />
      <InfoIcon sx={infoIconStyle} />
    </TextDisclosure>
  );
};

ServiceFeeDisclosure.propTypes = {
  isClickable: PropTypes.bool,
  onClick: PropTypes.func,
};
