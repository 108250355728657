import { useContext } from 'react';
import { useSelector } from 'react-redux';
import RemoteConfigContext from '@artemis/integrations/remoteConfig/context';
import { getActiveOrganizationId } from '@artemis/store/organization/selectors';

/**
 * Mobile users can go direct to checkout from menu (bypassing cart) when:
 * - rw_mobile_menu_checkout_enabled is true
 * - one of two conditions are met:
 *   - the user is on a subdomain and rw_mobile_menu_checkout_orgs_enabled is true
 *   - the user is NOT on a subdomain
 *
 * @returns {bool} true if user can create a group order
 */
const useCanMobileCartGoDirectToCheckout = () => {
  const { getBoolean } = useContext(RemoteConfigContext);
  const isMobileMenuCheckoutEnabled = getBoolean(
    'rw_mobile_menu_checkout_enabled',
  );
  const isMobileMenuCheckoutEnabledForOrgs = getBoolean(
    'rw_mobile_menu_checkout_orgs_enabled',
  );
  const organizationId = useSelector(getActiveOrganizationId);
  return (
    isMobileMenuCheckoutEnabled &&
    (isMobileMenuCheckoutEnabledForOrgs || !organizationId)
  );
};

export default useCanMobileCartGoDirectToCheckout;
