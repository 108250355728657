import { useSelector } from 'react-redux';
import {
  getCartDeliveryChargeMicro,
  getCartSubtotalMicro,
  getDiscountTotalMicro,
  getIsDeliveryCart,
  getMeetsMinimumDeliveryOrderValue,
} from '@artemis/store/cart/selectors';
import {
  getMerchantDeliveryCostCheckpoints,
  getMerchantDeliveryInfo,
} from '@artemis/store/merchant/selectors';

const useDeliveryChargeMicro = () => {
  const cartDeliveryChargeMicro = useSelector(getCartDeliveryChargeMicro);
  const cartSubtotalMicro = useSelector(getCartSubtotalMicro);
  const cartDiscountTotalMicro = useSelector(getDiscountTotalMicro);
  const isDelivery = useSelector(getIsDeliveryCart);
  const meetsMinDeliveryOrderValue = useSelector(
    getMeetsMinimumDeliveryOrderValue,
  );
  const { merchantDeliveryChargeMicro } = useSelector(getMerchantDeliveryInfo);
  const { freeDeliveryOrderValueMicro } = useSelector(
    getMerchantDeliveryCostCheckpoints,
  );

  // cartDeliveryChargeMicro is 0 when !meetsMinOrderValue
  // cartDeliveryChargeMicro is 0 when !isDelivery
  // This is undesirable so only use it when accurate
  if (
    cartDeliveryChargeMicro != null &&
    isDelivery &&
    meetsMinDeliveryOrderValue
  ) {
    return cartDeliveryChargeMicro;
  }
  // When pickup we want to show if the cart is eligible for free delivery
  // Assume freeDeliveryOrderValueMicro of 0 means it is disabled
  if (
    !isDelivery &&
    freeDeliveryOrderValueMicro > 0 &&
    cartSubtotalMicro + cartDiscountTotalMicro >= freeDeliveryOrderValueMicro
  ) {
    return 0;
  }
  // Fall back to the merchant's default delivery charge
  return merchantDeliveryChargeMicro;
};

export default useDeliveryChargeMicro;
