import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { PerkShape } from './propTypes';

const PrimaryText = styled.span`
  color: ${({ theme }) => theme.palette.primary};
`;

const StrikeoutText = styled.span`
  text-decoration: line-through;
`;

const RegularText = styled.span``;

export const ItemPrice = ({ price, perk }) => {
  if (perk) {
    const displayHint = perk.displayHint?.toLowerCase() || 'generic_discount';
    return (
      <FormattedMessage
        entry={`menu.perk.${displayHint}`}
        values={{
          strikeout: text => <StrikeoutText>{text}</StrikeoutText>,
          primary: text => <PrimaryText>{text}</PrimaryText>,
          originalPrice: perk.originalPrice,
          discountedPrice: perk.discountedPrice,
          savings: perk.savings,
          message: perk.menuHighlightMessage || perk.primaryMessage,
        }}
      />
    );
  }

  return <RegularText>{price}</RegularText>;
};

ItemPrice.propTypes = {
  price: PropTypes.string,
  perk: PerkShape,
};

export default ItemPrice;
