import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TimeRange } from '@artemis/components/TimeRange';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import {
  getCartEstimatedDeliveryTimeRangeMinutes,
  getIsDeliveryCart,
} from '@artemis/store/cart/selectors';
import {
  getCurrencyCode,
  getMerchantDeliveryInfo,
} from '@artemis/store/merchant/selectors';
import { formatCurrency } from '@artemis/utils/currency-format';
import { Caption, Label } from './components';
import useDeliveryChargeMicro from './useDeliveryChargeMicro';

const SEPARATOR = ' \u2022 ';

export const DeliveryLabel = () => {
  const currencyCode = useSelector(getCurrencyCode);
  const isDelivery = useSelector(getIsDeliveryCart);
  const cartDeliveryTimeRangeMinutes = useSelector(
    getCartEstimatedDeliveryTimeRangeMinutes,
  );
  const { estimatedDeliveryTimeInMinutes } = useSelector(
    getMerchantDeliveryInfo,
  );
  const deliveryChargeMicro = useDeliveryChargeMicro();

  const deliveryTimeRangeMinutes = isDelivery
    ? cartDeliveryTimeRangeMinutes
    : estimatedDeliveryTimeInMinutes;

  const showDeliveryEstimates =
    deliveryChargeMicro != null || !!deliveryTimeRangeMinutes;

  if (!showDeliveryEstimates) {
    return <DeliveryLabelWrapper />;
  }

  const deliveryCharge = () => {
    if (deliveryChargeMicro == null) return null;
    return deliveryChargeMicro === 0 ? (
      <FormattedMessage entry="delivery.free" />
    ) : (
      formatCurrency({
        value: deliveryChargeMicro,
        currencyCode,
      })
    );
  };

  return (
    <DeliveryLabelWrapper>
      {deliveryCharge()}
      {deliveryChargeMicro != null && !!deliveryTimeRangeMinutes && SEPARATOR}
      {deliveryTimeRangeMinutes && (
        <TimeRange {...deliveryTimeRangeMinutes} isShort />
      )}
    </DeliveryLabelWrapper>
  );
};

const DeliveryLabelWrapperProps = {
  children: PropTypes.node,
};
const DeliveryLabelWrapper = ({ children }) => {
  if (!children) {
    return <FormattedMessage entry="menu.delivery" />;
  }
  return (
    <div>
      <Label>
        <FormattedMessage entry="menu.delivery" />
      </Label>
      <Caption aria-hidden="true">{children}</Caption>
    </div>
  );
};

DeliveryLabelWrapper.propTypes = DeliveryLabelWrapperProps;
