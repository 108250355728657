import React, { useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import SwiperCore, { Navigation, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { polyfill } from 'seamless-scroll-polyfill';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import Loading from '@artemis/components/Loading';
import { getItemSearch } from '@artemis/store/menu/selectors';
import { clearItemQuery, setItemQuery } from '@artemis/store/menu/slice';
import { breakpoints } from '@artemis/theme/breakpoints';
import {
  FormattedMessage,
  useFormatMessage,
} from '@artemis/integrations/contentful/utils';

SwiperCore.use([Navigation, Mousewheel]);

const TAB_OFFSET = 20;
const TAB_PADDING = 17;

const Sections = styled.div`
  padding-bottom: 70vh;
  width: 100%;
  position: relative;
`;

const QuickAccessWrapper = styled.div`
  background-color: white;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey['200']};
  border-top: 1px solid ${({ theme }) => theme.palette.grey['200']};
  cursor: pointer;
  overflow-x: scroll;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
  position: relative;
  display: flex;
  transition: width 0.3s ease-in-out;

  ${({ theme }) => theme.noScrollbars};

  ${({ theme, $showSearchInput }) => theme.isTablet`
    & .swiper-slide:first-child {
      margin-left: -${TAB_OFFSET}px;
    }
    & .swiper-slide:last-child {
      margin-right: ${TAB_OFFSET}px;
    }
    ${$showSearchInput && 'width: 50%;'}
  `};

  ${({ $showSearchInput }) =>
    $showSearchInput &&
    css`
      width: 0;
    `}

  ${({ $hideBorders }) =>
    $hideBorders &&
    css`
      border: none;
      cursor: unset;
    `}
`;

const GlobalQuickAccessWrapper = styled(QuickAccessWrapper)`
  display: flex;
  position: fixed;
  left: 0;
  top: -100px;
  transition: top 0.5s ease;
  z-index: 1;

  ${({ showFixedBar }) => showFixedBar && 'top:0'}
`;

const TabWrapper = styled.div`
  scroll-margin-top: 80px;
`;

const QuickAccessTabStyle = styled.div`
  height: 3.75rem;
  line-height: 3.75rem;
  margin-right: 10px;
  padding-left: ${TAB_PADDING}px;
  padding-right: ${TAB_PADDING}px;
  border-color: transparent;
  transition: background-color 0.25s ease, border-color 0.25s ease;

  &:hover {
    background-color: ${({ theme }) =>
      theme.palette.hover.onLightBg(theme.palette.background.default)};
    border-bottom: 2px solid
      ${({ theme }) =>
        theme.palette.hover.onLightBg(theme.palette.background.default)};
  }

  ${({ isActive }) =>
    isActive &&
    `
    border-bottom: 2px solid black;

    &:hover{
      border-color: black;
    }
  `}

  ${({ isShort }) => isShort && `padding-left:0px`}
`;

const getDimensions = ele => {
  if (!ele) return {};
  const { height } = ele.getBoundingClientRect();
  const { offsetTop } = ele;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const scrollTo = ele => {
  ele.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });
};

const ScrollOverlay = styled.div`
  display: none;
  height: 3.75rem;
  line-height: 3.75rem;
  position: absolute;
  transition: right 0.3s ease-in-out;
  z-index: 1;
  ${props => props.theme.isTablet`
    display: block;
  `};
`;

const ScrollOverlayButton = styled.div`
  background: none;
  border-radius: 50%;
  border-color: white;
  cursor: pointer;
  margin-top: 16px;
  height: 32px;
  width: 32px;
`;

const ScrollOverlayReverseButton = styled(ScrollOverlayButton)`
  transform: rotate(180deg);
`;

const RelativeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 16px;
  position: relative;
  z-index: 0;
`;

const sharedPropTypes = {
  swiperRef: PropTypes.any,
  style: PropTypes.object,
};

const GoLeft = ({ swiperRef, style }) => (
  <ScrollOverlay style={style}>
    <ScrollOverlayReverseButton
      onClick={() => {
        swiperRef?.current?.swiper?.slidePrev();
      }}
    >
      <ResponsiveImage
        id="icons.chevronRightCircle.img"
        specificAlt="Previous"
      />
    </ScrollOverlayReverseButton>
  </ScrollOverlay>
);

GoLeft.propTypes = sharedPropTypes;

const GoRight = ({ swiperRef, style }) => (
  <ScrollOverlay style={style}>
    <ScrollOverlayButton
      onClick={() => {
        swiperRef?.current?.swiper?.slideNext();
      }}
    >
      <ResponsiveImage id="icons.chevronRightCircle.img" specificAlt="Next" />
    </ScrollOverlayButton>
  </ScrollOverlay>
);

GoRight.propTypes = sharedPropTypes;

const SearchContainer = styled.div`
  display: flex;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.palette.grey['200']};
  border-radius: 4pt;
  height: 62px;
  margin-left: 12px;
  width: 48px;
  transition: width 0.3s ease-in-out;

  ${({ theme, $showSearchInput }) => theme.isTablet`
  margin-left: 24px;
      ${$showSearchInput && `width: 50%;`}
`};

  ${({ $showSearchInput }) =>
    $showSearchInput &&
    css`
      margin-left: 0;
      width: 100%;
    `};
`;

const SearchButton = styled.button`
  all: unset;
  align-items: center;
  display: flex;

  ${({ $showSearchInput }) =>
    !$showSearchInput &&
    css`
      cursor: pointer;
    `};
`;

const SearchIcon = styled(ResponsiveImage).attrs({
  id: 'icons.search.img',
})`
  display: inline-block;
  padding: 0 12px;
  height: 24px;
  width: 24px;
`;

const ClearSearchButton = styled.button`
  all: unset;
  align-items: center;
  cursor: pointer;
  display: flex;
`;

const ClearSearchIcon = styled(ResponsiveImage).attrs({
  id: 'icons.clearsearch.img',
})`
  display: inline-block;
  padding: 0 12px;
  height: 24px;
  width: 24px;
`;

const SearchInput = styled.input`
  border: none;
  border-radius: 4pt;
  padding: 0;
  width: 0;
  :focus {
    outline: none;
  }

  ${({ $showSearchInput }) =>
    $showSearchInput &&
    css`
      padding: 0 0 0 4px;
      width: 100%;
    `};
`;

const SearchResult = styled.div`
  ${props => props.theme.typography.bodySmall};
  ${props => props.theme.typography.overflowEllipsis};
  padding: 16px 16px 0 16px;
  text-align: right;
`;

const NoSearchResult = styled(SearchResult)`
  text-align: center;
`;

const NoResultsContainer = styled.div`
  padding: 16px 16px 0 16px;
  margin: auto;
  width: 200px;

  ${props => props.theme.isTablet`
    padding-top 32px;
    width: 300px;
  `};
`;

const ScrollableTabs = ({ tabs, containerRef, isMenuLoading }) => {
  const dispatch = useDispatch();
  const itemSearch = useSelector(getItemSearch);

  const searchText = useFormatMessage({
    entry: 'menu.search',
  });

  const [highlightedSection, setHighlightedSection] = useState(0);
  const [showFixedBar, setShowFixedBar] = useState(false);

  const breakpointRef = useRef(null);
  const headerRef = useRef(null);
  const globalHeaderRef = useRef(null);
  const tabsRef = useRef([]);
  const searchButtonRef = useRef(null);
  const searchInputRef = useRef(null);

  const [isHeaderBeginning, setIsHeaderBeginning] = useState(true);
  const [isHeaderEnd, setIsHeaderEnd] = useState(false);
  const [isGlobalHeaderBeginning, setIsGlobalHeaderBeginning] = useState(true);
  const [isGlobalHeaderEnd, setIsGlobalHeaderEnd] = useState(false);

  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const onSearchQueryChange = e => {
    const { value } = e.target;
    setSearchQuery(value);
    dispatch(setItemQuery({ itemQuery: value }));
  };
  const focusSearchInput = () => {
    searchInputRef.current?.focus();
  };

  const changeTabFocus = ({ container }) => {
    const scrollPosition = container.scrollTop - 310;

    const activeIndex = tabsRef.current.findIndex(ele => {
      const { offsetBottom } = getDimensions(ele);
      return scrollPosition < offsetBottom;
    });

    const lastTab = tabsRef.current.length - 1;
    const selected = Math.min(Math.max(0, activeIndex), lastTab);
    const lastTabNotSelected = activeIndex >= 0 && activeIndex !== lastTab;
    if (lastTabNotSelected) {
      setHighlightedSection(selected);
    } else {
      setHighlightedSection(lastTab);
    }

    headerRef?.current?.swiper?.slideTo(
      lastTabNotSelected ? selected : lastTab,
    );
    globalHeaderRef?.current?.swiper?.slideTo(
      lastTabNotSelected ? selected : lastTab,
    );
  };
  const changeTabFocusDebounced = useMemo(
    () => debounce(changeTabFocus, 100),
    [],
  );

  const changeActiveTabBar = () => {
    const scrollPosition = containerRef.current.scrollTop;
    const { offsetBottom } = getDimensions(breakpointRef.current);
    setShowFixedBar(scrollPosition > offsetBottom);
  };
  const changeActiveTabBarDebounced = useMemo(
    () => debounce(changeActiveTabBar, 100),
    [],
  );

  const handleScroll = () => {
    changeActiveTabBarDebounced();
    changeTabFocusDebounced({
      container: containerRef.current,
    });
  };

  useEffect(() => {
    if (containerRef && containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScroll);
    }
    return () =>
      containerRef &&
      containerRef.current &&
      containerRef.current.removeEventListener('scroll', handleScroll);
  }, []);

  useEffectOnce(() => {
    polyfill();
  });

  const tabClick = index => {
    setHighlightedSection(index);
    scrollTo(tabsRef.current[index]);
  };

  if (!showSearchInput && (!tabs || tabs.length === 0)) {
    return null;
  }

  return (
    <div key="scrollable-content-wrapper">
      <RelativeWrapper ref={breakpointRef}>
        {!isHeaderBeginning && (
          <GoLeft
            showSearchInput={showSearchInput}
            swiperRef={headerRef}
            style={{ top: 0, left: 0, zIndex: 10 }}
          />
        )}
        <QuickAccessWrapper
          $showSearchInput={showSearchInput}
          $hideBorders={itemSearch.resultCount === 0}
          isHeaderBeginning={isHeaderBeginning}
          isHeaderEnd={isHeaderEnd}
        >
          <Swiper
            mousewheel
            slideToClickedSlide="true"
            slidesOffsetBefore={0}
            slidesPerView="auto"
            slidesPerGroup={1}
            slidesPerGroupAuto
            breakpoints={{
              [breakpoints.mobileMax]: {
                slidesOffsetBefore: TAB_OFFSET,
              },
              [breakpoints.tabletMax]: {
                slidesOffsetBefore: TAB_OFFSET,
              },
            }}
            ref={headerRef}
            onAfterInit={swiper => {
              setIsHeaderBeginning(swiper.isBeginning);
              setIsHeaderEnd(swiper.isEnd);
            }}
            onToEdge={swiper => {
              setIsHeaderBeginning(swiper.isBeginning);
              setIsHeaderEnd(swiper.isEnd);
            }}
            onSlideChange={swiper => {
              setIsHeaderBeginning(swiper.isBeginning);
              setIsHeaderEnd(swiper.isEnd);
            }}
          >
            {tabs.map(({ label, value }, index) => (
              <SwiperSlide
                style={{ width: 'fit-content' }}
                key={`scroll_${value}`}
              >
                <QuickAccessTabStyle
                  id={`scroll_${value}`}
                  key={`scroll_${value}`}
                  data-testid={`scroll_${value}`}
                  role="presentation"
                  onClick={() => tabClick(index)}
                  isActive={
                    highlightedSection === index ||
                    (index === 0 && highlightedSection === 0)
                  }
                  isShort={index === 0}
                >
                  {label}
                </QuickAccessTabStyle>
              </SwiperSlide>
            ))}
          </Swiper>
        </QuickAccessWrapper>

        {!isHeaderEnd && (
          <GoRight
            showSearchInput={showSearchInput}
            swiperRef={headerRef}
            style={{
              top: 0,
              right: showSearchInput ? 'calc(50% - 3px)' : '74px',
              zIndex: 10,
            }}
          />
        )}
        <SearchContainer $showSearchInput={showSearchInput}>
          <SearchButton
            $showSearchInput={showSearchInput}
            ref={searchButtonRef}
            type="button"
            onClick={() => {
              if (!showSearchInput) {
                focusSearchInput();
                setShowSearchInput(true);
              }
            }}
          >
            <SearchIcon />
          </SearchButton>
          <SearchInput
            $showSearchInput={showSearchInput}
            ref={searchInputRef}
            aria-label={searchText}
            placeholder={searchText}
            value={searchQuery}
            onChange={onSearchQueryChange}
            onBlur={e => {
              if (e.relatedTarget === searchButtonRef.current) {
                focusSearchInput();
              } else if (searchQuery === '') {
                setShowSearchInput(false);
              }
            }}
          />
          {searchQuery && (
            <ClearSearchButton
              type="button"
              onClick={() => {
                setSearchQuery('');
                dispatch(clearItemQuery());
                focusSearchInput();
              }}
            >
              <ClearSearchIcon />
            </ClearSearchButton>
          )}
        </SearchContainer>
      </RelativeWrapper>

      <GlobalQuickAccessWrapper showFixedBar={showFixedBar}>
        {!isGlobalHeaderBeginning && (
          <GoLeft
            swiperRef={globalHeaderRef}
            style={{ position: 'fixed', left: 0, zIndex: 10 }}
          />
        )}

        <Swiper
          mousewheel
          slideToClickedSlide="true"
          slidesOffsetBefore={0}
          slidesPerView="auto"
          slidesPerGroupAuto
          slidesPerGroup={1}
          ref={globalHeaderRef}
          breakpoints={{
            [breakpoints.mobileMax]: {
              slidesOffsetBefore: TAB_OFFSET + TAB_PADDING,
            },
            [breakpoints.tabletMax]: {
              slidesOffsetBefore: TAB_OFFSET + TAB_PADDING,
            },
          }}
          onAfterInit={swiper => {
            setIsGlobalHeaderBeginning(swiper.isBeginning);
            setIsGlobalHeaderEnd(swiper.isEnd);
          }}
          onToEdge={swiper => {
            setIsGlobalHeaderBeginning(swiper.isBeginning);
            setIsGlobalHeaderEnd(swiper.isEnd);
          }}
          onSlideChange={swiper => {
            setIsGlobalHeaderBeginning(swiper.isBeginning);
            setIsGlobalHeaderEnd(swiper.isEnd);
          }}
        >
          {tabs.map(({ label, value }, index) => (
            <SwiperSlide
              style={{ width: 'fit-content' }}
              key={`scroll_global_${value}`}
            >
              <QuickAccessTabStyle
                id={`scroll_global_${value}`}
                key={`scroll_global_${value}`}
                data-testid={`scroll_global_${value}`}
                role="presentation"
                onClick={() => tabClick(index)}
                isActive={
                  highlightedSection === index ||
                  (index === 0 && highlightedSection === 0)
                }
              >
                {label}
              </QuickAccessTabStyle>
            </SwiperSlide>
          ))}
        </Swiper>
        {!isGlobalHeaderEnd && (
          <GoRight
            showSearchInput={showSearchInput}
            swiperRef={globalHeaderRef}
            style={{ position: 'fixed', right: 0, zIndex: 10 }}
          />
        )}
      </GlobalQuickAccessWrapper>
      <Sections>
        <Loading isLoading={isMenuLoading} />
        {itemSearch.resultCount > 0 && (
          <SearchResult>
            <FormattedMessage
              entry="menu.matchesFound"
              values={{
                count: itemSearch.resultCount,
                query: itemSearch.displayQuery,
              }}
            />
          </SearchResult>
        )}
        {itemSearch.resultCount === 0 && (
          <>
            <NoResultsContainer>
              <ResponsiveImage id="notfound_icecream.img" />
            </NoResultsContainer>
            <NoSearchResult>
              <FormattedMessage
                entry="menu.noMatchesFound"
                values={{ query: itemSearch.displayQuery }}
              />
            </NoSearchResult>
          </>
        )}
        {tabs.map(({ value, tab }, index) => (
          <TabWrapper
            key={`frag_${value}`}
            ref={el => {
              tabsRef.current[index] = el;
            }}
          >
            {tab}
          </TabWrapper>
        ))}
      </Sections>
    </div>
  );
};

ScrollableTabs.propTypes = {
  tabs: PropTypes.array,
  containerRef: PropTypes.any,
  isMenuLoading: PropTypes.bool,
};

export default ScrollableTabs;
