import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { createFulfillment } from '@artemis/store/groupOrder/slice';
import AuthenticationContext from '@artemis/integrations/auth/AuthenticationContext';
import RemoteConfigContext from '@artemis/integrations/remoteConfig/context';
import { getIsOrganizationActive } from '@artemis/store/organization/selectors';
import {
  FULFILLMENT_VISIBILITY,
  FULFILLMENT_FLOW_TYPE,
} from '@artemis/utils/constants';
import { addQueryParams } from '@artemis/utils/query';
import {
  GO_CONSUMER_FLOW_PARAM,
  ON_LOG_IN_PARAM,
  ON_LOG_IN_VALUES,
} from '@artemis/utils/query/constants';
import usePersistedQuery from '@artemis/utils/query/usePersistedQuery';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';

export const useCreateGroupOrderFulfillment = ({
  merchantId,
  merchantName,
  deliveryAddressId,
}) => {
  const dispatch = useDispatch();
  const persistedQuery = usePersistedQuery();
  const { authenticated, setAuthModal } = useContext(AuthenticationContext);
  const { getBoolean } = useContext(RemoteConfigContext);
  const useConsumerFlowOnOrgSubdomains = getBoolean(
    'rw_group_order_pm_consumer_flow_enabled',
  );
  const isOrgSubdomain = useSelector(getIsOrganizationActive);

  const isConsumerFlowParamPresent = useQueryCheck(GO_CONSUMER_FLOW_PARAM);

  const useConsumerFlow =
    (useConsumerFlowOnOrgSubdomains && isOrgSubdomain) ||
    isConsumerFlowParamPresent;

  const fulfillmentFlowType = useConsumerFlow
    ? FULFILLMENT_FLOW_TYPE.HOST_ALWAYS_GUEST
    : FULFILLMENT_FLOW_TYPE.ADMIN_OPTIONAL_GUEST;

  const createGroupOrderFulfillment = () => {
    if (!authenticated) {
      setAuthModal({
        showLoginModal: true,
        image: 'login.piggyback.img',
        title: (
          <FormattedMessage
            entry="login.title.createGroupOrder"
            values={{ merchant: merchantName }}
          />
        ),
        loginMessage: ' ',
      });
      // Add query param to trigger GO creation after logging in
      const createGOQuery = {
        [ON_LOG_IN_PARAM]: ON_LOG_IN_VALUES.CREATE_GROUP,
      };
      addQueryParams(createGOQuery);
      return;
    }
    dispatchCreateFulfillment();
  };

  const dispatchCreateFulfillment = () => {
    dispatch(
      createFulfillment({
        fulfillment: {
          fulfillmentFlowType,
          visibility: FULFILLMENT_VISIBILITY.PUBLIC,
          merchantIds: [merchantId],
          scheduledDetails: {
            scheduled: !useConsumerFlow,
          },
          ...(useConsumerFlow ? { guestMax: 10 } : {}),
        },
        persistedQuery,
        deliveryAddressId,
      }),
    );
  };

  return [createGroupOrderFulfillment, dispatchCreateFulfillment];
};
