import { useContext, useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { persistStore } from 'redux-persist';
import AuthenticationContext from '@artemis/integrations/auth/AuthenticationContext';
import { useRemoteConfig } from '@artemis/integrations/remoteConfig';
import { clearExpiredLocalSelections } from '@artemis/store/localSelections/slice';

const usePersistLocalSelections = () => {
  const store = useStore();
  const dispatch = useDispatch();
  const { configLoaded, getBoolean, getNumber } = useRemoteConfig();
  const { authenticated, initialized } = useContext(AuthenticationContext);
  const [hasStorePersisted, setHasStorePersisted] = useState(false);
  const isEnabled = getBoolean('rw_local_selections_enabled');

  useEffect(() => {
    if (initialized && !authenticated && isEnabled) {
      // Persist store in hook where window is available after SSR
      persistStore(store, null, () => {
        // Callback after store is persisted
        setHasStorePersisted(true);
      });
    }
  }, [initialized, authenticated, isEnabled]);

  useEffect(() => {
    if (configLoaded && hasStorePersisted) {
      const daysUntilExpiry = getNumber('rw_local_selections_expiry_days');
      dispatch(clearExpiredLocalSelections({ daysUntilExpiry }));
    }
  }, [configLoaded, hasStorePersisted]);
};

export default usePersistLocalSelections;
