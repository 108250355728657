import { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import AuthenticationContext from '@artemis/integrations/auth/AuthenticationContext';
import { loadInvitation } from '@artemis/store/invite/slice';
import { INVITE_LINK_ID_PARAM } from './query/constants';

/**
 * Checks for an "invite_id" query parameter and loads information about the invite.
 *
 * This is currently used for Group Ordering invites. It was previously also used for private piggyback invites.
 */
const useLoadInvite = () => {
  const { query } = useRouter();
  const dispatch = useDispatch();
  const { initialized } = useContext(AuthenticationContext);

  const { [INVITE_LINK_ID_PARAM]: linkId } = query;

  // Treat all invites as private piggyback if group ordering is not enabled
  // Otherwise, load the invite from the invite service so we can check the type
  useEffect(() => {
    if (!linkId || !initialized) {
      return;
    }

    dispatch(loadInvitation({ linkId }));
  }, [linkId, initialized]);
};

export default useLoadInvite;
