import React, { useContext, useEffect } from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Toggle from '@artemis/components/Toggle';
import useIsDineIn from '@artemis/containers/Cart/useIsDineIn';
import AuthenticationContext from '@artemis/integrations/auth/AuthenticationContext';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import {
  getDeliveryAddressDetail,
  getIsDeliveryCart,
} from '@artemis/store/cart/selectors';
import {
  getDeliveryAddressesInitialized,
  getMostRecentDeliveryAddress,
} from '@artemis/store/deliveryAddresses/selectors';
import {
  openModal,
  setFulfillment,
  closeModal,
} from '@artemis/store/fulfillment/slice';
import {
  isFulfillmentModalOpen,
  getFulfillmentToggling,
} from '@artemis/store/fulfillment/selectors';
import { getMerchantId } from '@artemis/store/merchant/selectors';
import { FULFILLMENT_TYPE } from '@artemis/utils/constants';
import { useRemoveQueryParameter } from '@artemis/utils/query';
import {
  FORCE_DELIVERY_PARAM,
  PICKUP_PARAM,
} from '@artemis/utils/query/constants';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';
import { getSessionItem, SESSION_KEY } from '@artemis/utils/sessionStorage';
import { DeliveryLabel } from './DeliveryLabel';
import PickupLabelContainer from './PickupLabel';

const CartDeliveryAddressModal = dynamic(() =>
  import('@artemis/containers/DeliveryAddressModal/CartDeliveryAddressModal'),
);

const FulfillmentToggle = ({ supportsDelivery, hidePickup }) => {
  const { authenticated } = useContext(AuthenticationContext);
  const dispatch = useDispatch();
  const isDineIn = useIsDineIn();
  const removeQueryParameter = useRemoveQueryParameter();
  const [overrideDineInWithPickup, forceDelivery] = useQueryCheck([
    PICKUP_PARAM,
    FORCE_DELIVERY_PARAM,
  ]);

  const isDeliveryModalOpen = useSelector(isFulfillmentModalOpen);
  const isLoading = useSelector(getFulfillmentToggling);
  const isDelivery = useSelector(getIsDeliveryCart);
  const merchantId = useSelector(getMerchantId);
  const isAddressesInitialized = useSelector(getDeliveryAddressesInitialized);
  const recentDeliveryAddress = useSelector(getMostRecentDeliveryAddress);
  const addressDetail = useSelector(getDeliveryAddressDetail);
  const showDineInOption = isDineIn && !overrideDineInWithPickup;

  useEffect(() => {
    if (isAddressesInitialized) {
      if (hidePickup && !addressDetail) {
        // Automatically attempt to set to delivery if delivery is the only
        // fulfillment option available
        setToDelivery();
      } else if (forceDelivery && !addressDetail) {
        // Automatically attempt to set to delivery if default fulfillment type is delivery
        removeQueryParameter(FORCE_DELIVERY_PARAM);
        setToDelivery();
      } else if (forceDelivery && addressDetail) {
        // Remove the query param so that its effect only gets triggered once
        removeQueryParameter(FORCE_DELIVERY_PARAM);
      }
    }
  }, [isAddressesInitialized, hidePickup, addressDetail, forceDelivery]);

  const closeDeliveryModal = () => {
    dispatch(closeModal());
  };

  const setToDineIn = () => {
    if (!supportsDelivery) return;
    dispatch(
      setFulfillment({
        isToggling: true,
        merchantId,
        merchantFulfillmentType: FULFILLMENT_TYPE.DINE_IN,
      }),
    );
  };

  const setToPickup = () => {
    if (!supportsDelivery) return;
    dispatch(
      setFulfillment({
        isToggling: true,
        merchantId,
        merchantFulfillmentType: FULFILLMENT_TYPE.IN_STORE,
      }),
    );
  };

  const setToDelivery = () => {
    const guestAddressDetails = getSessionItem(
      SESSION_KEY.GUEST_ADDRESS_DETAILS,
    );

    if (isDelivery) {
      dispatch(openModal());
    } else if (authenticated && recentDeliveryAddress) {
      dispatch(
        setFulfillment({
          isToggling: true,
          merchantId,
          merchantFulfillmentType: FULFILLMENT_TYPE.DELIVERY,
          userDeliveryAddressId: recentDeliveryAddress.deliveryAddressId,
        }),
      );
    } else if (!authenticated && guestAddressDetails) {
      dispatch(
        setFulfillment({
          isToggling: true,
          merchantId,
          merchantFulfillmentType: FULFILLMENT_TYPE.DELIVERY,
          userDeliveryAddressDetail: guestAddressDetails,
        }),
      );
    } else {
      dispatch(openModal());
    }
  };

  const getToggleOptions = () => {
    const options = [];
    if (showDineInOption) {
      options.push({
        id: 'dineIn',
        label: <FormattedMessage entry="menu.dineIn" />,
        onClick: setToDineIn,
      });
    }
    if (!showDineInOption && !hidePickup) {
      options.push({
        id: 'pickup',
        label: <PickupLabelContainer />,
        onClick: setToPickup,
      });
    }
    if (supportsDelivery) {
      options.push({
        id: 'delivery',
        label: <DeliveryLabel />,
        onClick: setToDelivery,
        onClickActive: setToDelivery,
      });
    }
    return options;
  };

  let active = showDineInOption ? 'dineIn' : 'pickup';
  if (isDelivery) {
    active = 'delivery';
  }

  return (
    <>
      <Toggle
        active={active}
        isLoading={isLoading}
        options={getToggleOptions()}
      />
      {isDeliveryModalOpen && (
        <CartDeliveryAddressModal
          isOpen={isDeliveryModalOpen}
          onRequestClose={closeDeliveryModal}
        />
      )}
    </>
  );
};

FulfillmentToggle.propTypes = {
  supportsDelivery: PropTypes.bool,
  hidePickup: PropTypes.bool,
};

export default FulfillmentToggle;
