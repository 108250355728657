import React, { useState, useContext } from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import {
  getHasMinimumOrderValue,
  getMinimumOrderValue,
  getMaximumOrderValue,
  getHasMaximumOrderValue,
  getIsDeliveryCart,
  getPickupMinCartItemCount,
  getPickupMaxCartItemCount,
} from '@artemis/store/cart/selectors';
import {
  getMerchantName,
  getMerchantTodaysHours,
  getMerchantCategories,
  getMerchantImageUrl,
  getMerchantDeliveryCostCheckpoints,
  getHasFreeDeliveryOrderValue,
  getMerchantHighlightMessage,
  getShowServiceFeeDisclosure,
  getServiceFeeDisclosurePopup,
} from '@artemis/store/merchant/selectors';
import { getIsGroupOrder } from '@artemis/store/groupOrder/selectors';
import { setServiceFeeModalOpen } from '@artemis/store/menu/slice';
import SeparatorList from './SeparatorList';
import { ServiceFeeDisclosure } from './ServiceFeeDisclosure';

const StoreInfoModal = dynamic(() => import('./MoreInfoModal'));

const StoreInfoWrapper = styled.div`
  align-content: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  align-content: center;
  margin: auto 0;
  text-align: center;
`;

const MoreInfoButton = styled.button`
  ${props => props.theme.typography.body};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  background-color: rgba(0, 0, 0, 0);
  color: ${({ theme }) => theme.palette.primary};
  cursor: pointer;
  border: none;
  outline: none;
  transition: color 0.25s ease;

  &:hover {
    color: ${({ theme }) =>
      theme.palette.hover.onLightBg(theme.palette.primary)};
    text-decoration: underline;
  }
`;

const Body = styled.div`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.rtColors.black900};

  :not(:last-child) {
    margin-bottom: 4px;
  }
`;

const Icon = styled.img`
  margin: 0 auto;
  display: block;
  padding-bottom: 16px;
  width: 80px;
  height: 80px;
`;

const MerchantName = styled.h1`
  font-size: 1.5rem;
  font-weight: ${props => props.theme.typography.fontWeightBold};
  margin-top: 0;
  margin-bottom: 14px;
  :only-child {
    margin-top: 10px;
    margin-bottom: 0;
    ${props => props.theme.isTablet`
      margin-top: 0;
    `}
  }
`;

const DebugImage = styled.img`
  margin-bottom: 10px;
  max-width: 90%;
  max-height: 600px;
  ${props => props.theme.isTablet`
    max-width: 600px;
  `}
`;

const HighlightMessage = styled.div`
  background-color: ${props => props.theme.palette.primary};
  border-radius: 8px;
  color: ${props => props.theme.rtColors.white};
  padding: 12px 24px;
  text-align: left;
`;

const StoreInfo = ({
  className,
  overrideMerchantName,
  isDebugMode = false,
  hasInStoreParam = false,
}) => {
  const dispatch = useDispatch();
  const [isMoreInfoOpen, setIsMoreInfoOpen] = useState(false);
  const theme = useContext(ThemeContext);

  const { freeDeliveryOrderValue } = useSelector(
    getMerchantDeliveryCostCheckpoints,
  );
  const pickupMinCartItemCount = useSelector(getPickupMinCartItemCount);
  const pickupMaxCartItemCount = useSelector(getPickupMaxCartItemCount);
  const hasOrderMinValue = useSelector(getHasMinimumOrderValue);
  const hasOrderMaxValue = useSelector(getHasMaximumOrderValue);
  const minimumOrderValue = useSelector(getMinimumOrderValue);
  const maximumOrderValue = useSelector(getMaximumOrderValue);
  const hasFreeDeliveryValue = useSelector(getHasFreeDeliveryOrderValue);
  const todaysHours = useSelector(getMerchantTodaysHours);
  const categories = useSelector(getMerchantCategories);
  const merchantImage = useSelector(getMerchantImageUrl);
  const isDelivery = useSelector(getIsDeliveryCart);
  const isGroupOrder = useSelector(getIsGroupOrder);
  const name = useSelector(getMerchantName);
  const highlightMessage = useSelector(getMerchantHighlightMessage);
  const showServiceFeeDisclosure = useSelector(getShowServiceFeeDisclosure);
  const serviceFeeDisclosurePopup = useSelector(getServiceFeeDisclosurePopup);
  const merchantName = overrideMerchantName || name;
  const showOrderLimitations =
    (pickupMinCartItemCount > 0 ||
      pickupMaxCartItemCount > 0 ||
      hasOrderMinValue ||
      hasOrderMaxValue ||
      hasFreeDeliveryValue ||
      showServiceFeeDisclosure) &&
    !isGroupOrder;

  const requestClose = () => setIsMoreInfoOpen(false);
  const onOpenServiceFeeModal = () =>
    dispatch(setServiceFeeModalOpen({ isOpen: true }));

  if (hasInStoreParam) {
    return (
      <StoreInfoWrapper className={className}>
        {theme.iconUrl && (
          <Icon
            data-testid="merchant-icon"
            src={theme.iconUrl}
            width="80"
            height="80"
          />
        )}
        {isDebugMode && merchantImage && <DebugImage src={merchantImage} />}
        <MerchantName>{merchantName}</MerchantName>
        {isDebugMode && highlightMessage && (
          <HighlightMessage>{highlightMessage}</HighlightMessage>
        )}
      </StoreInfoWrapper>
    );
  }

  return (
    <StoreInfoWrapper className={className}>
      {theme.iconUrl && (
        <Icon
          data-testid="merchant-icon"
          src={theme.iconUrl}
          width="80"
          height="80"
        />
      )}
      {isDebugMode && merchantImage && <DebugImage src={merchantImage} />}
      <MerchantName>{merchantName}</MerchantName>
      {categories && (
        <Body>
          <SeparatorList>{categories}</SeparatorList>
        </Body>
      )}
      <Body>
        {todaysHours}{' '}
        <MoreInfoButton onClick={() => setIsMoreInfoOpen(true)}>
          <FormattedMessage entry="menu.store_info.more_info" />
        </MoreInfoButton>
      </Body>
      {isDebugMode && highlightMessage && (
        <HighlightMessage>{highlightMessage}</HighlightMessage>
      )}
      {showOrderLimitations && (
        <Body>
          <SeparatorList>
            {pickupMinCartItemCount > 0 && (
              <FormattedMessage
                entry="menu.minOrderItemCount"
                values={{
                  count: pickupMinCartItemCount,
                }}
              />
            )}
            {pickupMaxCartItemCount > 0 && (
              <FormattedMessage
                entry="menu.maxOrderItemCount"
                values={{
                  count: pickupMaxCartItemCount,
                }}
              />
            )}
            {hasOrderMinValue && (
              <FormattedMessage
                entry="delivery.minimumOrderValue"
                values={{ cost: minimumOrderValue }}
              />
            )}
            {hasOrderMaxValue && (
              <FormattedMessage
                entry="delivery.maximumOrderValue"
                values={{ cost: maximumOrderValue }}
              />
            )}
            {isDelivery && hasFreeDeliveryValue && (
              <FormattedMessage
                entry="delivery.freeOverOrderValue"
                values={{ cost: freeDeliveryOrderValue }}
              />
            )}
            {showServiceFeeDisclosure && (
              <ServiceFeeDisclosure
                isClickable={serviceFeeDisclosurePopup?.description}
                onClick={() => {
                  onOpenServiceFeeModal();
                }}
              />
            )}
          </SeparatorList>
        </Body>
      )}
      <StoreInfoModal isOpen={isMoreInfoOpen} onRequestClose={requestClose} />
    </StoreInfoWrapper>
  );
};

StoreInfo.propTypes = {
  className: PropTypes.string,
  overrideMerchantName: PropTypes.string,
  isDebugMode: PropTypes.bool,
  hasInStoreParam: PropTypes.bool,
};

export default StoreInfo;
