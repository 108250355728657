import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  align-self: center;
  color: ${({ theme }) => theme.rtColors.black500};
  border: 1px solid ${({ theme }) => theme.rtColors.black500};
  text-transform: uppercase;
  padding: 2px 6px;
  margin-left: 8px;
  border-radius: 67px;
  font-size: 11px;
  line-height: 1;
`;

const BetaTag = () => (
  <Container>
    <FormattedMessage entry="groupOrder.beta" />
  </Container>
);

export default BetaTag;
