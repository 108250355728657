import { useSelector } from 'react-redux';
import { getMerchantUrl } from '@artemis/store/merchant/selectors';
import usePersistedQuery from '@artemis/utils/query/usePersistedQuery';

const useCheckoutUrl = () => {
  const persistedQuery = usePersistedQuery();
  const merchantPath = useSelector(getMerchantUrl);

  return `${process.env.RT_BROWSER_ORIGIN}${merchantPath}/checkout${persistedQuery}`;
};

export default useCheckoutUrl;
