import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  getMerchantId,
  getMerchantName,
  getMerchantSupportsScheduling,
  getMerchantAuthMode,
} from '@artemis/store/merchant/selectors';
import { getScheduledForTime } from '@artemis/store/cart/selectors';
import { openModal as openSchedulingModal } from '@artemis/store/scheduling/slice';
import ScheduledForTime from '@artemis/components/ScheduledForTime';
import { logEvent, EVENT_PARAM_TYPE_STRING } from '@artemis/utils/analytics';
import useIsOrderLaterOnly from '@artemis/containers/Cart/useIsOrderLaterOnly';
import useIsDineIn from '@artemis/containers/Cart/useIsDineIn';
import { PICKUP_PARAM } from '@artemis/utils/query/constants';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';
import { EVENT_ACTION_SCHEDULING_MODAL_LAUNCH_CLICK } from './analytics';

const Button = styled.button`
  align-items: center;
  background: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.rtColors.black200};
  border-radius: 100px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  height: 48px;
  justify-content: center;
  padding: 0 6px 0 8px;
  > span {
    ${props => props.theme.typography.lineClamp(1)};
  }
`;

const SchedulingButton = () => {
  const dispatch = useDispatch();
  const overrideDineInWithPickup = useQueryCheck(PICKUP_PARAM);
  const isDineIn = useIsDineIn();
  const merchantId = useSelector(getMerchantId);
  const merchantName = useSelector(getMerchantName);
  const merchantSupportsScheduling = useSelector(getMerchantSupportsScheduling);
  const scheduledForTime = useSelector(getScheduledForTime);
  const showDineInOption = isDineIn && !overrideDineInWithPickup;
  const isSchedulingEnabled = merchantSupportsScheduling && !showDineInOption;
  const orderLaterOnly = useIsOrderLaterOnly();
  const merchantAuthMode = useSelector(getMerchantAuthMode);

  const handleSchedulingClick = () => {
    logEvent(EVENT_ACTION_SCHEDULING_MODAL_LAUNCH_CLICK, [
      {
        key: 'MERCHANT_ID',
        value: merchantId,
        type: EVENT_PARAM_TYPE_STRING,
      },
      {
        key: 'MERCHANT_NAME',
        value: merchantName,
        type: EVENT_PARAM_TYPE_STRING,
      },
      {
        key: 'MERCHANT_AUTH_MODE',
        value: merchantAuthMode,
        type: EVENT_PARAM_TYPE_STRING,
      },
    ]);

    dispatch(openSchedulingModal({}));
  };
  return (
    <>
      {isSchedulingEnabled && (
        <Button
          data-testid="scheduling-button"
          onClick={() => handleSchedulingClick()}
        >
          <ScheduleIcon
            sx={{
              color: 'black',
              fontSize: 20,
              marginRight: '10px',
              verticalAlign: 'middle',
            }}
          />
          <span>
            <ScheduledForTime
              scheduledForTime={scheduledForTime}
              orderLaterOnly={orderLaterOnly}
            />
          </span>
          <ExpandMoreIcon
            sx={{
              color: 'black',
              fontSize: 24,
              marginLeft: '12px',
              verticalAlign: 'middle',
            }}
          />
        </Button>
      )}
    </>
  );
};

export default SchedulingButton;
