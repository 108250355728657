import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import Loading from '@artemis/components/Loading';
import BetaTag from '../BetaTag';

const LinkButton = styled.button`
  ${props => props.theme.typography.bodySmall};
  background: none;
  border: 0;
  cursor: pointer;
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  padding: 0;
  display: grid;
  grid-template-columns: 24px auto 32px;
  grid-gap: 16px;
  width: 100%;
  align-items: center;
  border: 1px solid ${props => props.theme.rtColors.black200};
  border-radius: 4pt;
  padding: 16px;
  transition: 0.25s box-shadow;
  &:hover,
  &:focus {
    box-shadow: ${props => props.theme.shadows.shadow1};
  }
  position: relative;
  ${({ theme }) => theme.isDesktop`
    padding: 20px 24px;
  `}
`;

const Icon = styled(ResponsiveImage)`
  flex: 0 0 auto;
  height: ${props => props.$size}px;
  width: ${props => props.$size}px;
`;

const TextWrapper = styled.div`
  text-align: start;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  text-align: start;
`;

const StyledLoading = styled(Loading)`
  border-radius: 4pt;
`;

const CreateGroupOrderButton = ({ onClick, isLoading = false }) => (
  <LinkButton onClick={onClick}>
    <StyledLoading isLoading={isLoading} />
    <Icon
      data-testid="start-icon"
      id="groupOrder.startGroupOrder.img"
      specificAlt=" "
      $size={24}
    />
    <TextWrapper>
      <FormattedMessage entry="groupOrder.startGroupOrder" />
      <BetaTag />
    </TextWrapper>
    <Icon id="icons.chevronRightCircle.img" $size={32} />
  </LinkButton>
);

CreateGroupOrderButton.propTypes = {
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default CreateGroupOrderButton;
