import React from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import { useFormatMessage } from '@artemis/integrations/contentful/utils';
import { getMerchantStreetAddress } from '@artemis/store/merchant/selectors';
import { getIsMarketplace } from '@artemis/store/order/selectors';
import {
  BACK_PARAM,
  CATALOG_ID_PARAM,
  FILTER_CITY_PARAM,
  FILTER_CUISINE_PARAM,
  IN_STORE_PARAM,
  LIST_TYPE_PARAM,
  MERCHANT_GROUP_REFERER_PARAM,
  OWNER_ID_PARAM,
  CHECKOUT_CODE_PARAM,
  WORKSPACE_ID_PARAM,
  FILTER_CATERING_PARAM,
} from '@artemis/utils/query/constants';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';
import usePersistedQuery from '@artemis/utils/query/usePersistedQuery';

const BackIcon = styled(ResponsiveImage)`
  display: flex;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  width: 15px;
  height: 15px;
  margin: auto 0;
  margin-right: 12px;
  flex: 0 0 15px;
`;

const BackButtonWrapper = styled.a`
  align-content: center;
  display: flex;
  text-decoration: none;
  width: 50%;

  ${({ theme }) => theme.isTablet`
    width: auto;
  `}
`;

const BackText = styled.div`
  width: calc(100% - 37px);

  ${({ theme }) => theme.isTablet`
      width: auto;
    `}
`;

const Caption = styled.p`
  ${({ theme }) => theme.typography.caption};
  color: ${({ theme }) => theme.palette.grey[600]};
`;
const Address = styled.p`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  color: ${({ theme }) => theme.palette.common.black};
  ${({ theme }) => theme.typography.overflowEllipsis}
  width: 100%;

  ${({ theme }) => theme.isTablet`
    width: auto;
    white-space: normal;
    text-overflow: initial;
    overflow: initial;
  `}
`;

const BackButton = () => {
  const { query } = useRouter();

  const omitQueries = [
    MERCHANT_GROUP_REFERER_PARAM,
    LIST_TYPE_PARAM,
    CATALOG_ID_PARAM,
    OWNER_ID_PARAM,
    FILTER_CITY_PARAM,
    FILTER_CUISINE_PARAM,
  ];
  const {
    [CATALOG_ID_PARAM]: catalogId,
    [WORKSPACE_ID_PARAM]: workspaceId,
    [LIST_TYPE_PARAM]: listType,
    [FILTER_CITY_PARAM]: city,
    [FILTER_CUISINE_PARAM]: cuisine,
    [MERCHANT_GROUP_REFERER_PARAM]: merchantGroupId,
    [BACK_PARAM]: backParam,
    [CHECKOUT_CODE_PARAM]: checkoutCode,
    [FILTER_CATERING_PARAM]: catering,
  } = query;
  if (!catalogId) omitQueries.push(WORKSPACE_ID_PARAM);
  const persistedQuery = usePersistedQuery({
    omit: omitQueries,
  });
  const isInStore = useQueryCheck(IN_STORE_PARAM);
  const storeLocation = useFormatMessage({ entry: 'menu.store_location' });
  const backToList = useFormatMessage({ entry: 'menu.backToList' });
  const back = useFormatMessage({ entry: 'global.back' });
  const streetAddress = useSelector(getMerchantStreetAddress);
  const isMarketplace = useSelector(getIsMarketplace);

  if (isInStore) return null;

  // Back to List page
  if (listType) {
    const isCatalogPage = !!catalogId;

    const href = isCatalogPage
      ? `/order/list/${catalogId}${persistedQuery}`
      : `/order/workspace/${workspaceId}${persistedQuery}`;

    return (
      <BackButtonWrapper data-testid="menu-back" href={href}>
        <BackIcon id="back.svg.img" />
        <BackText>
          <Address>{backToList}</Address>
        </BackText>
      </BackButtonWrapper>
    );
  }

  // Back to group page, promotion pages
  if (merchantGroupId || checkoutCode) {
    return (
      <BackButtonWrapper
        data-testid="menu-back"
        href={`/order/${
          backParam || checkoutCode || merchantGroupId
        }${persistedQuery}`}
      >
        <BackIcon id="back.svg.img" />
        <BackText>
          <Caption>{storeLocation}</Caption>
          <Address>{streetAddress}</Address>
        </BackText>
      </BackButtonWrapper>
    );
  }

  // Back to RW landing page
  if (isMarketplace) {
    const cityString = city ? `/city/${city}` : '';
    const cuisineString = cuisine ? `/cuisine/${cuisine}` : '';
    const cateringString = catering ? '/catering' : '';
    const href = `${process.env.RT_BROWSER_ORIGIN}/order${cateringString}${cityString}${cuisineString}${persistedQuery}`;
    return (
      <BackButtonWrapper data-testid="menu-back" href={href} aria-label={back}>
        <BackIcon id="back.svg.img" />
        <BackText>
          <Address>{back}</Address>
        </BackText>
      </BackButtonWrapper>
    );
  }

  return null;
};

export default BackButton;
