import { logEvent, EVENT_PARAM_TYPE_STRING } from '@artemis/utils/analytics';

const EVENT_ACTION_MERCHANT_MENU_IMPRESSION = 'MERCHANT_MENU_IMPRESSION';
export const EVENT_ACTION_LANGUAGE_SELECTOR_IMPRESSION =
  'ROO_LANGUAGE_SELECTOR_IMPRESSION';
export const EVENT_ACTION_LANGUAGE_SELECT = 'ROO_LANGUAGE_SELECT';
export const EVENT_ACTION_CHECKOUT_PLUS_INFO_CLICK = 'CHECKOUT_PLUS_INFO_CLICK';
export const EVENT_ACTION_CHECKOUT_PLUS_INFO_GOT_IT_CLICK =
  'CHECKOUT_PLUS_INFO_GOT_IT_CLICK';

export const logImpression = ({
  merchantData,
  source,
  merchantAuthMode,
  impressionSource,
  userAgent,
}) => {
  const categories =
    merchantData &&
    merchantData.categories &&
    merchantData.categories.join('|');
  logEvent(EVENT_ACTION_MERCHANT_MENU_IMPRESSION, [
    {
      key: 'MERCHANT_ID',
      value: merchantData?.id,
      type: EVENT_PARAM_TYPE_STRING,
    },
    {
      key: 'MERCHANT_NAME',
      value: merchantData?.name,
      type: EVENT_PARAM_TYPE_STRING,
    },
    {
      key: 'CATEGORIES',
      value: categories,
      type: EVENT_PARAM_TYPE_STRING,
    },
    {
      key: 'PM_LIST_PAGE_SOURCE',
      value: source,
      type: EVENT_PARAM_TYPE_STRING,
    },
    {
      key: 'MERCHANT_AUTH_MODE',
      value: merchantAuthMode,
      type: EVENT_PARAM_TYPE_STRING,
    },
    {
      key: 'IMPRESSION_SOURCE',
      value: impressionSource,
      type: EVENT_PARAM_TYPE_STRING,
    },
    {
      key: 'USER_AGENT',
      value: userAgent,
      type: EVENT_PARAM_TYPE_STRING,
    },
  ]);
};
