import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DineInInput from '@artemis/components/DineInInput/DineInInput';
import FulfillmentToggle from '@artemis/containers/FulfillmentToggle';
import SchedulingButton from '@artemis/containers/SchedulingButton';
import CreateGroupOrderButton from '@artemis/components/GroupOrder/CreateGroupOrderButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMerchantId,
  getMerchantName,
} from '@artemis/store/merchant/selectors';
import { getIsCreateFulfillmentLoading } from '@artemis/store/groupOrder/selectors';
import AuthenticationContext from '@artemis/integrations/auth/AuthenticationContext';
import { useRemoveQueryParameter } from '@artemis/utils/query';
import {
  MENU_DEBUG_PARAM,
  ON_LOG_IN_PARAM,
  ON_LOG_IN_VALUES,
} from '@artemis/utils/query/constants';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';
import { useCreateGroupOrderFulfillment } from '@artemis/utils/useCreateGroupOrderFulfillment';
import { logGroupOrderMenuCreateClick } from '@artemis/containers/GroupOrder/analytics';
import { getUserDeliveryAddressId } from '@artemis/store/cart/selectors';
import { setHostInfoModal } from '@artemis/store/groupOrder/slice';
import useCanCreateGroupOrder from './useCanCreateGroupOrder';

const StyledDineInInput = styled(DineInInput)`
  padding: 16px 0;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 16px;
  margin: 0 0 16px;
  ${({ theme }) => theme.isDesktop`
    grid-auto-flow: column;
    justify-content: center;
    &:nth-child(2) {
      margin-left: 16px;
    }
  `}
`;

const Row = styled.div`
  ${({ theme }) => theme.isDesktop`
    display: flex;
    justify-content: center;
  `}
`;

const OrderOptions = ({
  className,
  supportsDelivery,
  hidePickup,
  hasInStoreParam = false,
}) => {
  const dispatch = useDispatch();
  const { authenticated, initialized } = useContext(AuthenticationContext);
  const canCreateGroupOrder = useCanCreateGroupOrder();
  const [onLogIn, isDebugMode] = useQueryCheck([
    ON_LOG_IN_PARAM,
    MENU_DEBUG_PARAM,
  ]);
  const removeQueryParameter = useRemoveQueryParameter();
  const merchantId = useSelector(getMerchantId);
  const merchantName = useSelector(getMerchantName);
  const isGroupOrderLoading = useSelector(getIsCreateFulfillmentLoading);
  const deliveryAddressId = useSelector(getUserDeliveryAddressId);

  const [createGroupOrderFulfillment, dispatchCreateFulfillment] =
    useCreateGroupOrderFulfillment({
      merchantId,
      merchantName,
      deliveryAddressId,
    });

  useEffect(() => {
    if (
      authenticated &&
      initialized &&
      onLogIn === ON_LOG_IN_VALUES.CREATE_GROUP
    ) {
      removeQueryParameter(ON_LOG_IN_PARAM);
      dispatchCreateFulfillment();
    }
  }, [authenticated, initialized]);

  const onClick = () => {
    logGroupOrderMenuCreateClick({ merchantId });

    if (authenticated) {
      createGroupOrderFulfillment();
    } else {
      dispatch(setHostInfoModal({ isOpen: true }));
    }
  };

  if (isDebugMode || hasInStoreParam) {
    return null;
  }

  return (
    <div className={className}>
      <StyledDineInInput />
      <Row>
        <ButtonContainer>
          <FulfillmentToggle
            supportsDelivery={supportsDelivery}
            hidePickup={hidePickup}
          />
          <SchedulingButton />
        </ButtonContainer>
      </Row>
      {canCreateGroupOrder && (
        <CreateGroupOrderButton
          onClick={onClick}
          isLoading={isGroupOrderLoading}
        />
      )}
    </div>
  );
};

OrderOptions.propTypes = {
  supportsDelivery: PropTypes.bool,
  className: PropTypes.string,
  hidePickup: PropTypes.bool,
  hasInStoreParam: PropTypes.bool,
};

export default OrderOptions;
