import styled from 'styled-components';

export const Label = styled.div`
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  line-height: 1.1;
`;

export const Caption = styled.div`
  ${props => props.theme.typography.caption};
  color: ${props => props.theme.rtColors.black900};
`;
