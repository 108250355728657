import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import Avatar from '@artemis/components/Avatar';
import { GroupDisplayShape } from '../propTypes';
import FriendlyDateMessage from '../FriendlyDateMessage';

const Container = styled.div`
  border-radius: 8px;
  border: 1px solid ${props => props.theme.rtColors.black200};
  padding: 16px;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  gap: 8px;
`;

const StyledAvatar = styled(Avatar)`
  flex-shrink: 0;
`;

const Info = styled.div`
  ${props => props.theme.isTablet`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 2;
  `}
`;

const Primary = styled.p`
  ${props => props.theme.typography.body};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  line-height: 1.2;
  margin-bottom: 4px;
`;

const Secondary = styled.p`
  ${props => props.theme.typography.bodySmall};
  color: ${props => props.theme.rtColors.black700};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  line-height: 1.2;
`;

const Action = styled.div`
  align-items: center;
  display: flex;
  padding-top: 6px;
  ${props => props.theme.isTablet`
    padding-top: 0;
  `}
  > button {
    padding: 0;
  }
`;

const GroupOrderStatusProps = {
  className: PropTypes.string,
  groupDisplay: GroupDisplayShape,
  isConsumerFlow: PropTypes.bool,
  isCurrentUserHost: PropTypes.bool,
  scheduled: PropTypes.bool.isRequired,
  orderByTime: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
  viewButton: PropTypes.node,
};

export const GroupOrderStatus = ({
  className,
  groupDisplay,
  isConsumerFlow,
  isCurrentUserHost,
  scheduled,
  orderByTime,
  timeZone,
  viewButton,
}) => {
  const isHostingConsumerFlow = isConsumerFlow && isCurrentUserHost;
  let secondaryMessage;
  if (isHostingConsumerFlow) {
    secondaryMessage = (
      <FormattedMessage entry="groupOrder.status.checkOutWhenEveryone" />
    );
  } else if (scheduled && orderByTime) {
    secondaryMessage = (
      <FriendlyDateMessage
        date={orderByTime}
        timeZone={timeZone}
        todayEntry="groupOrder.status.mustCompleteBy.today"
        tomorrowEntry="groupOrder.status.mustCompleteBy.tomorrow"
        otherEntry="groupOrder.status.mustCompleteBy.other"
      />
    );
  }

  return (
    <Container className={className}>
      <StyledAvatar
        imageUrl={groupDisplay?.imageUrl}
        fullName={groupDisplay?.name}
        size="lg"
      />
      <Info>
        <div>
          <Primary>
            <FormattedMessage
              entry={
                isHostingConsumerFlow
                  ? 'groupOrder.status.hostingGroupOrder'
                  : 'groupOrder.status.joinedGroupOrder'
              }
              values={{ name: groupDisplay?.name }}
            />
          </Primary>
          {secondaryMessage && <Secondary>{secondaryMessage}</Secondary>}
        </div>
        <Action>{viewButton}</Action>
      </Info>
    </Container>
  );
};

GroupOrderStatus.propTypes = GroupOrderStatusProps;
