import React from 'react';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Image = styled.img`
  border-radius: 50%;
  object-fit: cover;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
`;

const Container = styled.div`
  border-radius: 50%;
  background-color: ${props => props.theme.rtColors.black1000};
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  color: white;
  line-height: ${props => `${props.size}px`};
  text-align: center;
  font-size: ${props => `${props.size * 0.5}px`};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const getInitials = fullName =>
  fullName
    .split(' ')
    .filter(word => word.trim().length > 0)
    .slice(0, 2)
    .map(word => word[0].toUpperCase())
    .join('');

export const SIZES = {
  md: 'md',
  lg: 'lg',
  xl: 'xl',
};

const mapSize = {
  md: 30,
  lg: 44,
  xl: 52,
};

const Avatar = ({ fullName, imageUrl, size = SIZES.md, ...props }) => {
  const mappedSize = mapSize[size];

  if (imageUrl) {
    return <Image src={imageUrl} alt={fullName} size={mappedSize} {...props} />;
  }
  if (fullName) {
    return (
      <Container size={mappedSize} {...props}>
        {getInitials(fullName)}
      </Container>
    );
  }
  return (
    <Container size={mappedSize} {...props}>
      <PersonRoundedIcon sx={{ color: 'white', fontSize: mappedSize * 0.8 }} />
    </Container>
  );
};

Avatar.propTypes = {
  /** Full name, which will be the alt text of the image. When no image is provided, initial will be rendered */
  fullName: PropTypes.string,
  /** The url of the image */
  imageUrl: PropTypes.string,
  /** size of the avatar */
  size: PropTypes.oneOf(Object.values(SIZES)),
};

export default Avatar;
