import isObject from 'lodash/isObject';
import { useRouter } from 'next/router';

export const useRemoveQueryParameter = () => {
  const router = useRouter();
  return paramName => {
    const query = { ...router.query };
    delete query[paramName];

    router.push({ query }, null, {
      replace: true,
      shallow: true,
      scroll: false,
    });
  };
};

export const useAddQueryParams = () => {
  const router = useRouter();
  return newParams => {
    const query = { ...router.query, ...newParams };

    router.push({ query }, null, {
      replace: true,
      shallow: true,
      scroll: false,
    });
  };
};

/**
 * attach the query param to the end of url. If it already exist, replace the old one.
 * newQueries: an object of the query keys and values to be added
 *
 * Deprecated: This function doesn't update the query through the router, so may cause a mismatch between
 * the router state and actual URL in some cases. Use the above `useAddQueryParams` instead
 */
export const addQueryParams = newQueries => {
  if (!isObject(newQueries)) {
    throw Error('addQueryParams only accept object');
  }
  const keys = Object.keys(newQueries);
  const params = new URLSearchParams(window.location.search);
  keys.forEach(key => {
    params.delete(key);
    params.append(key, newQueries[key]);
  });
  const queries = params.toString();
  const { pathname } = window.location;

  window.history.replaceState(null, '', `${pathname}?${queries}`);
};
