import React from 'react';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { useSelector } from 'react-redux';
import { getMerchantUsesTakeaway } from '@artemis/store/merchant/selectors';
import FulfillmentTypeLabel from './FulfillmentTypeLabel';

const PickupLabel = () => {
  const shouldDisplayTakeaway = useSelector(getMerchantUsesTakeaway);

  return (
    <FulfillmentTypeLabel
      label={
        <FormattedMessage
          entry={shouldDisplayTakeaway ? 'menu.takeaway' : 'menu.pickup'}
        />
      }
    />
  );
};

export default PickupLabel;
